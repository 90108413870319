export const features = [
  {
    id: "sf_sync",
    name: "Salesforce Sync",
    h1: "Always in Sync With Your CRM",
    copy: `
    Momentum seamlessly integrates with Salesforce, ensuring that your CRM data is always accurate and up to date. Say goodbye to manual data entry and focus on meaningfully connecting with your donors.
  `,
  },
  {
    id: "bb_sync",
    name: "BlackBaud Sync",
    h1: "Effortless Sync With Blackbaud",
    copy: `
    Stay effortlessly connected with Blackbaud. Momentum ensures your CRM data is always current, freeing you from manual updates and allowing you to focus on impactful donor relationships.
    `,
  },
  {
    id: "fundraiser_care",
    name: "Caring for Fundraisers",
    h1: "Software Made For Fundraisers",
    copy: `
    Fundraisers are at the heart of successful campaigns. Momentum takes care of the administrative burden, allowing fundraisers to focus on what they do best: building genuine and lasting relationships with donors.
    `,
  },
  {
    id: "integrations",
    name: "Integrations",
    h1: "Momentum Integrations",
    copy: `
    Connect Momentum seamlessly with the tools you already rely on. Our integrations bring critical data to your fingertips exactly when you need it, streamlining your workflow and maximizing efficiency.
    `,
  },
  {
    id: "rec_engine",
    name: "The Momentum Recommendation Engine",
    h1: "Who Should You Contact Next?",
    copy: `
    Momentum's recommendation engine intelligently scans your entire CRM and highlights the best donor opportunities—whether it's a birthday, a giving milestone, or just the right moment to reconnect.
    `,
  },
  {
    id: "basic_inbox",
    name: "The Momentum Inbox",
    h1: "Your Momentum Inbox",
    copy: `
    Stay on top of your outreach with our intuitive interface. Momentum provides a prioritized list of actions and drafts personalized outreach for you, making it simple to maintain meaningful connections with donors.
    `,
  },
  {
    id: "fundraising_simplified",
    name: "Simple Fundraising",
    h1: "Fundraising Simplified",
    copy: `
    Momentum takes the complexity out of fundraising. With our user-friendly platform, staying organized and engaging donors becomes straightforward, empowering you to maximize your impact.
    `,
  },
  {
    id: "trust",
    name: "Trust & Security",
    h1: "AI You Can Trust",
    copy: `
    Trust is a core value of Momentum. Our platform is HIPAA compliant, SOC 2 certified, and built with robust security measures to protect your data, giving you peace of mind that your information is secure within Momentum.
    `,
  },
  {
    id: "notifications",
    name: "Notifications",
    h1: "Your Next Action",
    copy: `
    Never miss an important opportunity. Momentum notifies you of key donor events, like birthdays or giving anniversaries, ensuring you connect with them when it matters most.
    `,
  },
  {
    id: "templates",
    name: "Templates",
    h1: "Simple Outreach with Templates",
    copy: `
    Create outreach templates for your most common communications and personalize them effortlessly with mail merge. Let our AI help you draft smarter, more impactful messages.
    `,
  },
  {
    id: "data_aggregation",
    name: "Data Aggregation",
    h1: "Drafts with Your Data",
    copy: `
    Momentum pulls together donor data from a variety of sources to create highly personalized messages, ensuring every communication resonates in your authentic voice.
    `,
  },
  {
    id: "prospecting",
    name: "Prospecting in Momentum",
    h1: "Find Your Hidden Gems",
    copy: `
    Discover new donor opportunities without spending hours digging through data. Momentum's AI-powered prospecting tools analyze the data for you, helping you identify high-potential prospects and effectively engage them.`,
  },
  {
    id: "higher_ed_prospecting",
    name: "Higher Ed Prospecting",
    h1: "Prospecting designed for Higher Ed",
    copy: `
      Work with prospecting tools designed for your industry. Leverage AI tools that understand alumni relations and the unique needs of higher education fundraisers.
    `,
  },
  {
    id: "gift_predictions",
    name: "Gift Predictions",
    h1: "When Will They Give Next?",
    copy: `
    Momentum's AI gift predictions forecasts when donors are likely to give next and estimates their potential contributions, helping you plan your outreach with precision.
    `,
  },
  {
    id: "drafting",
    name: "AI Drafting",
    h1: "Instant AI Email Drafts",
    copy: `
    Momentum's AI drafting tools use past donor interactions to craft personalized outreach messages, ensuring every email aligns perfectly with your donor relationships.
    `,
  },
  {
    id: "engagement_plan",
    name: "Engagement Plans",
    h1: "Dynamic Engagement Plans",
    copy: `
    Let Momentum guide your donor interactions. Our AI model analyzes giving history and other key data points to create tailored engagement plans, helping you build stronger, longer-lasting relationships.
    `,
  },
  {
    id: "overview",
    name: "Momentum Basics",
    h1: "What is Momentum?",
    copy: `
    Momentum is your ultimate fundraising assistant. It tells you who to contact, when to reach out, and what to say—providing everything you need for effective, proactive engagement.
    `,
  },
  {
    id: "segmentation",
    name: "Donor Segmentation",
    h1: "Custom Segments for Targeted Outreach",
    copy: `
    Segment your donors based on any criteria you choose. Use those tailored segments to make your outreach more relevant, personal, and impactful.
    `,
  },
  {
    id: "tone_of_voice",
    name: "Tone of Voice",
    h1: "Your Tone of Voice",
    copy: `
    Momentum adapts to your unique tone of voice, drafting emails that sound like they were written by you. The more you use our AI tool, the more it accurately represents your voice and tone. 
    `,
  },
  {
    id: "outreach",
    name: "AI Donor Outreach",
    h1: "AI Donor Outreach",
    copy: `
    Manage your outreach effortlessly. Momentum drafts the perfect message for each donor, making review and sending as simple as a single click.
    `,
  },
  {
    id: "ai_prompts",
    name: "AI Prompts",
    h1: "Outreach That Writes Itself",
    copy: `
    Momentum's AI prompts help you kick-start your outreach journey. Follow our step-by-step guidance to craft impactful, engaging messages easily.
    `,
  },
  // - higher ed
  {
    id: "higher_education",
    name: "Momentum for Higher Education",
    h1: "Made For Higher Education",
    copy: `
     Momentum is built for higher education. We understand the unique needs of higher education fundraisers and have built a product that is designed specifically for you.
    `,
  },
  // - healthcare
  {
    id: "healthcare",
    name: "Momentum for Healthcare",
    h1: "Made For Healthcare",
    copy: `
    Momentum is HIPAA compliant and built for healthcare. We understand the unique needs of healthcare fundraisers and have built a product designed for you.
    `,
  },
  // - cadences
  {
    id: "cadences",
    name: "Cadences",
    h1: "Plan Ahead With Cadences",
    copy: `
    Momentum's cadences help you stay on top of your outreach. We've built in best practices for different types of outreach and can help you craft a cadence that works for every donor.
    `,
  },
  // - planned giving
  {
    id: "planned_giving",
    name: "Planned Giving",
    h1: "We Love Planned Giving",
    copy: `
    Momentum's planned giving tools help you stay on top of your outreach. We understand the journey toward the whole gift and can help you craft a plan that works for every donor.
    `,
  },
  // - annual giving
  {
    id: "annual_giving",
    name: "Annual Giving",
    h1: "We Love Annual Giving",
    copy: `
    Momentum's annual giving tools help you stay on top of your outreach. We understand the annual gift lifecycle and can help you craft a donor engagement plan for every donor
    `,
  },
  {
    id: "major_gift_fundraising",
    name: "Major Gift Fundraising",
    h1: "Major Gifts Made Easy",
    copy: `
    Momentum's major gift fundraising tools help you stay on top of your outreach. We understand the journey toward the major gift and can help you craft a donor engagement plan for every donor in your portfolio.
    `,
  },
  // - mobile contact reports
  {
    id: "mobile_contact_reports",
    name: "Mobile Contact Reports",
    h1: "From the Coffee Shop to the Car",
    copy: `
    Momentum's mobile contact reports give you the ability to quickly log donor interactions, no matter where you are.
    `,
  },
  // - crm cleaning
  {
    id: "crm_cleaning",
    name: "CRM Cleaning",
    h1: "Clean Up Your CRM",
    copy: `
    Momentum automatically syncs any information inputted to Momentum, from emails to contact reports, directly to your CRM in a structured format. Get used to a cleaner, up-to-date CRM. 
    `,
  },
  // dashboards
  {
    id: "dashboards",
    name: "Dashboards",
    h1: "Data-Driven Insights",
    copy: `
    Momentum's dashboards give you a snapshot of your fundraising progress, allowing you to stay on top of your goals and make data-driven decisions.
    `,
  },
  // automations
  {
    id: "automations",
    name: "Automations",
    h1: "Your Fundraising, Your Rules",
    copy: `
    Momentum's automations give you the ability to automate your outreach, no matter how complex. We've built in best practices for different types of outreach and can help you craft a plan for every donor in your portfolio.
    `,
  },
  {
    id: "general_ai",
    name: "Leaders in AI",
    h1: "Leaders in AI",
    copy: `
    Momentum is a leader in AI. We pride ourselves on being at the forefront of AI innovation and are committed to pushing the boundaries of what's possible.
    `,
  },
  {
    id: "ai_safety",
    name: "AI Safety",
    h1: "Leaders in AI Safety",
    copy: `
    Momentum is committed to AI safety. We're leaders in the space and are dedicated to building a safe and secure platform for all users.
    `,
  },
];

// todo: send to julie

// missing:

export const journeys = {
  // all: features.map((f) => f.id),
  default: [
    "overview",
    "ai_prompts",
    "data_aggregation",
    "basic_inbox",
    "fundraising_simplified",
  ],
  // Specific
  data: [
    "overview",
    "trust",
    "bb_sync",
    "integrations",
    "fundraising_simplified",
  ],
  prospecting: [
    "overview",
    "prospecting",
    "ai_prompts",
    "tone_of_voice",
    "fundraiser_care",
  ],
};
