import * as React from "react";
import {
  Animator,
  batch,
  FadeIn,
  FadeOut,
  MoveIn,
  ScrollContainer,
  ScrollPage,
  ZoomIn,
  ZoomOut,
} from "react-scroll-motion";
// import { useIsVisible } from "react-is-visible";

import {
  Box,
  Center,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
  Button,
} from "@chakra-ui/react";

import { features, journeys } from "./cms";
import JourneyBuilder from "./components/JourneyBuilder";

const SHIFT_AMOUNT = 200;

export const IMAGE_BG = "gray.300";

function IntroScroll() {
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [activeFeatures, setActiveFeatures] = React.useState([]);

  React.useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);

    // Add keyboard shortcut listener
    const handleKeyPress = (e) => {
      if (e.metaKey && e.key === "j") {
        // metaKey is Cmd on Mac
        e.preventDefault();
        window.location.search = "?new";
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    // Check for admin mode
    setIsAdmin(queryParams.get("new") !== null);

    // First check for specific journey
    const journeyParam = queryParams.get("journey");
    if (journeyParam && journeys[journeyParam]) {
      const journeyFeatures = journeys[journeyParam]
        .map((id) => features.find((f) => f.id === id))
        .filter(Boolean);
      setActiveFeatures(journeyFeatures);
      return;
    }

    // If no journey specified, check for features parameter
    const featureParam = queryParams.get("features");
    if (featureParam) {
      const featureIds = featureParam.split("|");
      const selectedFeatures = featureIds
        .map((id) => features.find((f) => f.id === id))
        .filter(Boolean);
      setActiveFeatures(selectedFeatures);
      return;
    }

    // If neither parameter exists, use default journey
    const defaultFeatures = journeys.default
      .map((id) => features.find((f) => f.id === id))
      .filter(Boolean);
    setActiveFeatures(defaultFeatures);

    // Cleanup listener on unmount
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  if (isAdmin) {
    return <JourneyBuilder />;
  }

  return (
    <Box bg="black">
      <ScrollContainer>
        {["intro", ...activeFeatures, "outro"].map((message, i) => {
          if (message === "intro")
            return (
              <ScrollPage key="intro">
                <Flex h="100vh" w={"100%"} alignItems={"center"}>
                  <Box
                    height={{ base: "400px", md: "100vh" }}
                    bgImage={"/intro.png"}
                    bgSize={"cover"}
                    bgPos={"center center"}
                    w={"100%"}
                  />
                </Flex>
              </ScrollPage>
            );
          if (message === "outro")
            return (
              <ScrollPage key="outro">
                <Center height={"100%"} bg="#2C306C">
                  <Stack spacing={6} textAlign="center">
                    <Text
                      color="white"
                      fontSize={{ base: "2xl", md: "4xl" }}
                      fontWeight="bold"
                      px={8}
                    >
                      Ready to transform your fundraising?
                    </Text>
                    <Button
                      as="a"
                      href="https://www.givemomentum.com/get-started"
                      size="lg"
                      colorScheme="blue"
                      color="white"
                      fontSize={"20px"}
                      px={8}
                      py={6}
                      _hover={{ transform: "scale(1.05)" }}
                      transition="all 0.2s"
                      mx={4}
                      leftIcon={<Image src="/m_logo.png" h="24px" />}
                    >
                      Get Started with Momentum
                    </Button>
                  </Stack>
                </Center>
              </ScrollPage>
            );
          return (
            <ScrollPage key={message.id}>
              <Stack
                gap={0}
                flex={1}
                justifyContent={{ base: "center", md: "center" }}
                alignItems={"center"}
                h={"100vh"}
                w={"100%"}
                maxW={"90vw"}
                mx={{ base: 4, md: 0 }}
              >
                <Flex
                  flexDir={{ base: "column", md: "row" }}
                  justifyContent={{ base: "flex-start", md: "center" }}
                  alignItems={{ base: "center", md: "flex-start" }}
                >
                  <Animator animation={batch(ZoomIn(0, 1), ZoomOut(1, 0))}>
                    <Center
                      mb={{ base: 4, md: 6 }}
                      width={{ base: "80px", md: "100px" }}
                      height={{ base: "80px", md: "100px" }}
                      borderRadius="full"
                      bg="#2C306C"
                      fontSize={{ base: "3xl", md: "5xl" }}
                      fontWeight="bold"
                      color="white"
                      mt={-6}
                    >
                      {i}
                    </Center>
                  </Animator>
                  <Animator
                    animation={batch(
                      MoveIn(i % 2 ? SHIFT_AMOUNT : SHIFT_AMOUNT, 0),
                      FadeOut(3, -1)
                    )}
                  >
                    <Heading color="white" px={4}>
                      {message.h1}
                    </Heading>
                    {message.copy.split("\n").map((snippet) => (
                      <Text
                        // textAlign={{ base: "center" }}
                        fontSize={{ base: "15px", md: "20px" }}
                        mb={4}
                        lineHeight={"1.2"}
                        color="white"
                        w={"100%"}
                        maxW={740}
                        minW={360}
                        px={4}
                        textShadow={"0 0 10px rgba(0,0,0,0.5)"}
                      >
                        {snippet.trim()}
                      </Text>
                    ))}

                    <Box
                      display={"flex"}
                      flexDir={"column"}
                      justifyContent={"space-between"}
                      px={4}
                    >
                      <Animator animation={batch(MoveIn(-1000, 0))}>
                        <Image
                          mx={"auto"}
                          w={"100%"}
                          maxW={740}
                          mt={2}
                          src={`features/${message.id}.png`}
                          bg={IMAGE_BG}
                        />
                      </Animator>

                      <Animator animation={batch(MoveIn(0, 0), FadeIn(-25, 1))}>
                        <Center mt={6} justifyContent={"flex-start"}>
                          <Button
                            as="a"
                            href="https://www.givemomentum.com/get-started"
                            size="md"
                            colorScheme="blue"
                            px={6}
                            py={4}
                            _hover={{ transform: "scale(1.05)" }}
                            transition="all 0.2s"
                            leftIcon={<Image src="/m_logo.png" h="20px" />}
                            // bg="#2C306C"
                          >
                            Learn More about {message.name} →
                          </Button>
                        </Center>
                      </Animator>
                    </Box>
                  </Animator>
                </Flex>
              </Stack>
            </ScrollPage>
          );
        })}
      </ScrollContainer>
    </Box>
  );
}
export default IntroScroll;
