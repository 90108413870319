import * as React from "react";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import {
  Box,
  Button,
  Flex,
  Stack,
  Text,
  Image,
  Input,
  Center,
  InputGroup,
  InputRightElement,
  CloseButton,
} from "@chakra-ui/react";
import { features, journeys } from "../cms";
import { IMAGE_BG } from "../App";

function JourneyBuilder() {
  const [selectedFeatures, setSelectedFeatures] = React.useState([]);

  // Compute available features by filtering out selected ones
  const [searchTerm, setSearchTerm] = React.useState("");
  const [availableFeatures, setAvailableFeatures] = React.useState(
    features.filter(
      (feature) =>
        !selectedFeatures.some((selected) => selected.id === feature.id)
    )
  );
  React.useEffect(() => {
    setAvailableFeatures(
      features.filter(
        (feature) =>
          !selectedFeatures.some((selected) => selected.id === feature.id) &&
          (feature.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            feature.h1.toLowerCase().includes(searchTerm.toLowerCase()) ||
            feature.copy.toLowerCase().includes(searchTerm.toLowerCase()))
      )
    );
  }, [selectedFeatures, searchTerm]);

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // Drop outside any droppable
    if (!destination) return;

    // Handle moving between columns
    if (source.droppableId !== destination.droppableId) {
      if (
        source.droppableId === "available" &&
        destination.droppableId === "journey"
      ) {
        // Moving from available to journey
        const feature = availableFeatures[source.index];
        setSelectedFeatures((prev) => {
          const newFeatures = Array.from(prev);
          newFeatures.splice(destination.index, 0, feature);
          return newFeatures;
        });
      } else if (
        source.droppableId === "journey" &&
        destination.droppableId === "available"
      ) {
        // Moving from journey back to available
        setSelectedFeatures((prev) =>
          prev.filter((_, index) => index !== source.index)
        );
      }
      return;
    }

    // Handle reordering within journey
    if (
      source.droppableId === "journey" &&
      destination.droppableId === "journey"
    ) {
      const newFeatures = Array.from(selectedFeatures);
      const [reorderedItem] = newFeatures.splice(source.index, 1);
      newFeatures.splice(destination.index, 0, reorderedItem);
      setSelectedFeatures(newFeatures);
    }
  };

  return (
    <Box p={8} pt={4} bg="black" h="100vh" overflow="hidden">
      <DragDropContext onDragEnd={onDragEnd}>
        <Flex gap={8} color="white" h="calc(100vh - 40px)">
          <Box flex={1}>
            {/* <Box mb={4}>
              <Text fontSize="xl" fontWeight="bold" mb={2} color="white">
                Quick Intros
              </Text>
              <Stack direction="row" spacing={2}>
                {Object.entries(journeys).map(([journeyName, featureIds]) => (
                  <Button
                    key={journeyName}
                    colorScheme="blue"
                    variant="outline"
                    size="sm"
                    onClick={() => {
                      const journeyFeatures = featureIds
                        .map((id) => features.find((f) => f.id === id))
                        .filter(Boolean)
                        .filter(
                          (feature) =>
                            !selectedFeatures.some(
                              (selected) => selected.id === feature.id
                            )
                        );
                      setSelectedFeatures(journeyFeatures);
                    }}
                  >
                    {journeyName.charAt(0).toUpperCase() + journeyName.slice(1)}
                  </Button>
                ))}
              </Stack>
            </Box> */}
            <Flex alignItems={"center"} justifyContent={"space-between"} mt={0}>
              <Text fontSize="xl" fontWeight="bold">
                Build an Intro
              </Text>
              <Box mb={2} mt={0}>
                <Flex>
                  <InputGroup size="sm" maxW="200px" w="200px">
                    <Input
                      placeholder="Search features..."
                      bg="whiteAlpha.100"
                      color="white"
                      borderColor="whiteAlpha.300"
                      _placeholder={{ color: "whiteAlpha.500" }}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      onDragStart={(e) => {
                        // Clear input value when dragging starts
                        setSearchTerm("");
                      }}
                    />
                    {searchTerm && (
                      <InputRightElement>
                        <CloseButton
                          size="sm"
                          onClick={() => setSearchTerm("")}
                          color="whiteAlpha.600"
                        />
                      </InputRightElement>
                    )}
                  </InputGroup>
                </Flex>
              </Box>
            </Flex>

            <Droppable droppableId="available">
              {(provided, snapshot) => (
                <Stack
                  spacing={2}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  bg={
                    snapshot.isDraggingOver ? "whiteAlpha.100" : "transparent"
                  }
                  borderRadius="md"
                  p={2}
                  h="calc(100% - 40px)"
                  overflowY="auto"
                  sx={{
                    "&::-webkit-scrollbar": {
                      width: "8px",
                    },
                    "&::-webkit-scrollbar-track": {
                      background: "whiteAlpha.100",
                      borderRadius: "full",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      background: "whiteAlpha.300",
                      borderRadius: "full",
                    },
                  }}
                >
                  {!availableFeatures?.length && searchTerm && (
                    <Center color="#fff">
                      <Text mt={12}>No results</Text>
                    </Center>
                  )}
                  {availableFeatures
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((feature, index) => (
                      <Draggable
                        key={feature.id}
                        draggableId={`available-${feature.id}`}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <Flex
                            alignItems={"center"}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            p={2}
                            borderWidth="1px"
                            borderRadius="md"
                            borderColor={"whiteAlpha.500"}
                            bg={
                              snapshot.isDragging
                                ? "blackAlpha.300"
                                : "transparent"
                            }
                            boxShadow={snapshot.isDragging ? "lg" : "none"}
                            cursor="grab"
                            _active={{ cursor: "grabbing" }}
                          >
                            {/* <Box bg="gray.200" w={"200px"}> */}
                            <Image
                              w={"200px"}
                              src={`/features/${feature.id}.png`}
                              borderRadius="sm"
                              bg={IMAGE_BG}
                            />
                            {/* </Box> */}
                            <Box>
                              <Flex align={"flex-end"}>
                                <Text color="blue.300" fontSize="xs" ml={2}>
                                  {searchTerm
                                    ? feature.name
                                        .split(
                                          new RegExp(`(${searchTerm})`, "gi")
                                        )
                                        .map((part, i) =>
                                          part.toLowerCase() ===
                                          searchTerm.toLowerCase() ? (
                                            <Box
                                              as="span"
                                              key={i}
                                              bg="yellow.700"
                                            >
                                              {part}
                                            </Box>
                                          ) : (
                                            part
                                          )
                                        )
                                    : feature.name}
                                </Text>
                                <Text
                                  fontSize={"xx-small"}
                                  ml={1}
                                  color={"blue.300"}
                                  mb={"1px"}
                                >
                                  <pre>
                                    {"<"}
                                    {feature.id}
                                    {">"}
                                  </pre>
                                </Text>
                              </Flex>
                              <Text
                                fontSize="md"
                                fontWeight={"bold"}
                                ml={2}
                                color="#fff"
                              >
                                {searchTerm
                                  ? feature.h1
                                      .split(
                                        new RegExp(`(${searchTerm})`, "gi")
                                      )
                                      .map((part, i) =>
                                        part.toLowerCase() ===
                                        searchTerm.toLowerCase() ? (
                                          <Box
                                            as="span"
                                            key={i}
                                            bg="yellow.700"
                                          >
                                            {part}
                                          </Box>
                                        ) : (
                                          part
                                        )
                                      )
                                  : feature.h1}
                              </Text>
                              <Text fontSize="xs" ml={2} color="gray.400">
                                {searchTerm
                                  ? (feature.copy.length > 200
                                      ? `${feature.copy.slice(0, 200)}...`
                                      : feature.copy
                                    )
                                      .split(
                                        new RegExp(`(${searchTerm})`, "gi")
                                      )
                                      .map((part, i) =>
                                        part.toLowerCase() ===
                                        searchTerm.toLowerCase() ? (
                                          <Box
                                            as="span"
                                            key={i}
                                            bg="yellow.700"
                                          >
                                            {part}
                                          </Box>
                                        ) : (
                                          part
                                        )
                                      )
                                  : feature.copy}
                              </Text>
                            </Box>
                          </Flex>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </Stack>
              )}
            </Droppable>
          </Box>

          <Box flex={1} display="flex" flexDirection="column" mt={0} h="100%">
            <Box flex="1" overflowY="hidden">
              <Droppable droppableId="journey">
                {(provided, snapshot) => (
                  <Stack
                    spacing={2}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    bg={
                      snapshot.isDraggingOver ? "blackAlpha.400" : "transparent"
                    }
                    borderRadius="md"
                    p={4}
                    borderWidth="2px"
                    borderStyle="dashed"
                    h="100%"
                    overflowY="auto"
                    position="relative"
                    data-rbd-droppable-context-id={
                      provided.droppableProps["data-rbd-droppable-context-id"]
                    }
                    sx={{
                      "&::-webkit-scrollbar": {
                        width: "8px",
                      },
                      "&::-webkit-scrollbar-track": {
                        background: "whiteAlpha.100",
                        borderRadius: "full",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        background: "whiteAlpha.300",
                        borderRadius: "full",
                      },
                      "[data-rbd-placeholder-context-id]": {
                        height: "80px !important",
                      },
                    }}
                  >
                    {selectedFeatures.length === 0 && (
                      <Text
                        position="absolute"
                        top="50%"
                        left="50%"
                        transform="translate(-50%, -50%)"
                        color="whiteAlpha.500"
                        fontSize="lg"
                      >
                        Drag features to build your intro
                      </Text>
                    )}
                    {selectedFeatures.map((feature, index) => (
                      <Draggable
                        key={feature.id}
                        draggableId={`journey-${feature.id}`}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <Box
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            p={2}
                            borderWidth="1px"
                            borderRadius="md"
                            bg={
                              snapshot.isDragging
                                ? "blackAlpha.300"
                                : "transparent"
                            }
                            boxShadow={snapshot.isDragging ? "lg" : "none"}
                            cursor="grab"
                            _active={{ cursor: "grabbing" }}
                          >
                            <Flex justify="space-between" align="center">
                              <Image
                                w={"300px"}
                                src={`/features/${feature.id}.png`}
                                borderRadius="sm"
                                bg={IMAGE_BG}
                              />
                              <Box>
                                <Text fontSize="lg" ml={2} fontWeight={"bold"}>
                                  {feature.h1}
                                </Text>
                                <Text fontSize="sm" ml={2} color="gray.400">
                                  {feature.copy}
                                </Text>
                              </Box>
                            </Flex>
                          </Box>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Stack>
                )}
              </Droppable>
            </Box>
            <Flex justify={"space-between"} mt={4}>
              {selectedFeatures.length > 0 && (
                <Button
                  mr={2}
                  colorScheme="blackAlpha"
                  bg="gray.800"
                  onClick={() => {
                    setSelectedFeatures([]);
                  }}
                >
                  Start Over
                </Button>
              )}
              <Button
                flex={1}
                colorScheme="blue"
                onClick={() => {
                  const featureIds = selectedFeatures
                    .map((f) => f.id)
                    .join("|");
                  const url = `${window.location.pathname}?features=${featureIds}`;
                  window.location.href = url;
                }}
              >
                Generate Link →
              </Button>
            </Flex>
          </Box>
        </Flex>
      </DragDropContext>
    </Box>
  );
}

export default JourneyBuilder;
